import React from "react";
import { FullPageWrapperBgImage, Title, DivFlexVertical, ColoredTextBox } from '../styles/styles'
import  { withFirebase } from '../utils/firebase';
import bgImage from '../assets/bg-main.jpg';
import logo from '../assets/logo.svg';
import Signup from '../components/Signup';

const LandingPage = ( props ) => {

  const loginRedirect = props.redirect || '/trailList'

  return (
    <div>
      <FullPageWrapperBgImage bgImage={bgImage} >
        <DivFlexVertical backblured>
          <img alt="The Mantrailing App Logo" src={logo} width="100px" />
          <Title>The Mantrailing App - Web <ColoredTextBox><i>Beta</i></ColoredTextBox></Title>
          <Signup redirect={loginRedirect}/>
        </DivFlexVertical>
      </FullPageWrapperBgImage>
    </div>
  );
};

export default withFirebase(LandingPage);
