export const getWeatherUrl = (weather) => {
  switch (weather) {
    case 0:
      return "https://openweathermap.org/img/wn/01d@2x.png"
    case 1:
      return "https://openweathermap.org/img/wn/02d@2x.png"
    case 2:
      return "https://openweathermap.org/img/wn/03d@2x.png"
    case 3:
      return "https://openweathermap.org/img/wn/04d@2x.png"
    case 4:
      return "https://openweathermap.org/img/wn/09d@2x.png"
    case 5:
      return "https://openweathermap.org/img/wn/10d@2x.png"
    case 6:
      return "https://openweathermap.org/img/wn/11d@2x.png"
    case 7:
      return "https://openweathermap.org/img/wn/13d@2x.png"
    case 8:
      return "https://openweathermap.org/img/wn/50d@2x.png"
    default:
      return "https://openweathermap.org/img/wn/01d@2x.png"
  }
}

export const formatDate = (unixTime) => {
  let date = new Date(unixTime * 1000);
  return date.toLocaleDateString(undefined)
}

export const formatDateTime = (unixTime) => {
  let date = new Date(unixTime * 1000);
  return date.toLocaleString(undefined)
}

export const getTime = (startTime, endTime) => {
  return new Date((endTime - startTime) * 1000).toISOString().substr(11, 8);
}

export const getDistance = (distance) => {
  return (Math.round(((distance / 1000.0) + Number.EPSILON) * 100) / 100).toLocaleString(undefined) + " km";
}

export const sortPoints = (points) => {
  const compare = (a, b) => {
    if ( a.key < b.key ){
      return -1;
    }
    if ( a.key > b.key ){
      return 1;
    }
    return 0;
  }

  return points.sort(compare);
}

export const getBlind = (blind, t) => {

  switch (blind) {
    case 0:
      return t('knowing')
    case 1:
      return t('single_blind')
    case 2:
      return t('double_blind')
    default:
      return ""
  }
}

export const getFocusAccom = (focusAccom, t) => {
  switch (focusAccom) {
    case 0:
      return t('y_no_help')
    case 1:
      return t('y_help')
    case 2:
      return t('no')
    default:
      return ""
  }
}

export const getWeather = (weather, t) => {
  switch (weather) {
    case 0:
      return t('w1')
    case 1:
      return t('w2')
    case 2:
      return t('w3')
    case 3:
      return t('w4')
    case 4:
      return t('w5')
    case 5:
      return t('w6')
    case 6:
      return t('w7')
    case 7:
      return t('w8')
    case 8:
      return t('w9')
    default:
      return ""
  }
}

export const getArea = (area, t) => {
  switch (area) {
    case 0:
      return t('city')
    case 1:
      return t('woods')
    case 2:
      return t('mountains')
    case 3:
      return t('indoor')
    case 4:
      return t('mixed')
    case 5:
      return t('rureal')
    default:
      return ""
  }
}

export const getDeviation = (deviation, t) => {
  if (deviation === -1 || deviation === undefined) {
    return t('no_runner')
  }
  return (deviation).toLocaleString(undefined) + " m"
}

export const getTemperature = (temperature) => {
  return temperature + " °C"
}

export const getWindspeed = (windSpeed) => {
  return (windSpeed).toLocaleString(undefined) + " m/s";
}

export const getAirpressure = (airPressure) => {
  return (airPressure).toLocaleString(undefined) + " hPa";
}

export const getAirhumidity = (airHumidity) => {
  return (airHumidity).toLocaleString(undefined) + " %";
}

export const getAge = (layTime, startTime, t) => {
  const seconds = startTime - layTime;
  var d = Math.floor(seconds / (3600*24));
  var newSeconds = seconds - (d * 24 * 3600);
  var h = Math.floor(newSeconds / 3600);
  newSeconds = newSeconds - (h * 3600);
  var m = Math.floor(newSeconds / 60);

  if (layTime === 0) {
    return t('minuteWithCount', {count: 0});
  }

  var returnString = '';
  if (d > 0) {
    returnString += t('dayWithCount', {count: d});
  }
  if (h > 0) {
    returnString += " " + t('hourWithCount', {count: h});
  }
  if (m > 0) {
    returnString += " " + t('minuteWithCount', {count: m});
  }

  return returnString;
}
