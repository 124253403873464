import React, { useState } from "react";
import {withFirebase
} from '../utils/firebase';
import NavBar from '../components/NavBar';
import {
  makeStyles
} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Alert from '@material-ui/lab/Alert';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    padding: '2vw'
  },
  root: {
    minWidth: 275,
  },
  input: {
    width: '100%',
  },
  title: {
    fontSize: 14,
  },
  activeButton: {
    background: '#4caf50',
    color: 'white'
  },
  defaultButton: {

  },
  alert: {
    margin: '2vw',
  },
});

const AccountSettingsPage = (props) => {

  const { t, i18n } = useTranslation();

  const classes = useStyles();
  const [email, setEmail] = useState('');
  const [emailConfirm, setEmailConfirm] = useState('');
  const [alertContent, setAlertContent] = useState({
    title: '',
    severity: ''
  });

  function validateEmail(mail) {
    return (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) || mail === '';
  }

  const canSendMail = () => {
    return validateEmail(email) && emailConfirm === email && email !== '';
  }

  const updateMail = () => {
    if (canSendMail()) {

      let user = props.firebase.auth.currentUser;
      //console.log(props.firebase.auth.EmailAuthProvider.providerId);

      // Check, that user only has email-provider associated
      if (user.providerData.length > 1 || user.providerData[0].providerId !== 'password') {
        setAlertContent({
          title: t('cant_change_mail'),
          severity: 'error'
        });
        return;
      }

      user.updateEmail(email).then(function() {
        setAlertContent({
          title: t('email_successfull'),
          severity: 'success'
        });
      }).catch(function(error) {
        setAlertContent({
          title: t('email_try_again'),
          severity: 'error'
        });
      });
    } else {
      setAlertContent({
        title: t('email_no_match'),
        severity: 'warning'
      });
    }
  }

  // Page should be protected using PrivateRoute
  return ( <
    div >
    <
    NavBar title = {t("account_settings")} / > {
      alertContent.severity !== '' ? < Alert className = {
        classes.alert
      }
      severity = {
        alertContent.severity
      } > {
        alertContent.title
      } < /Alert> : <div / >
    } <
    div className = {
      classes.container
    } >
    <
    Card className = {
      classes.root
    }
    variant = "outlined" >
    <
    CardContent >
    <
    Typography className = {
      classes.title
    }
    color = "textSecondary"
    gutterBottom >
    {t('update_email')} <
    /Typography> <
    br / >
    <
    TextField error = {!validateEmail(email)
    }
    onChange = {
      (event) => setEmail(event.target.value)
    }
    id = "standard-basic"
    label = {t('new_mail')}
    className = {
      classes.input
    }
    /> <
    br / >
    <
    br / >
    <
    TextField error = {!validateEmail(emailConfirm) || (emailConfirm !== email && emailConfirm !== '')
    }
    onChange = {
      (event) => setEmailConfirm(event.target.value)
    }
    id = "standard-basic"
    label = {t('conf_mail')}
    className = {
      classes.input
    }
    /> <
    br / >
    <
    /CardContent> <
    CardActions >
    <
    Button className = {
      canSendMail() ? classes.activeButton : classes.defaultButton
    }
    onClick = {
      () => updateMail()
    }
    size = "small" > {t('save_changes')} < /Button> <
    /CardActions> <
    /Card> <
    /div> <
    /div>
  );

};

export default withFirebase(AccountSettingsPage);
