import React from "react";
import  { withFirebase } from '../utils/firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Toolbar, IconButton, Typography, Button, MenuItem, Menu } from '@material-ui/core';
import AccountCircle from '@material-ui/icons/AccountCircle';
import history from "../utils/history";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import logo from '../assets/logo.svg';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

const NavBar = ( props ) => {
  const { t, i18n } = useTranslation();

  const firebase = props.firebase;
  const title = props.title;

  const [user, loading, error] = useAuthState(firebase.auth);
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose} >
      <MenuItem onClick={() => {handleMenuClose(); history.push('/account-settings')}}>{t('account_settings')}</MenuItem>
      <MenuItem onClick={() => {handleMenuClose(); firebase.auth.signOut(); history.push('/')}}>{t('logout')}</MenuItem>
    </Menu>
  );

  var accountButton = loading || user === null ? <Button onClick={ () => history.push('/')} color="inherit">{t('login')}</Button> : <MenuItem onClick={handleProfileMenuOpen}>
      <IconButton aria-label="account of current user" aria-controls="primary-search-account-menu" aria-haspopup="true" color="inherit" > <AccountCircle /> </IconButton></MenuItem>;

  const backButton = props.backLink !== undefined ? <IconButton edge="start" onClick={ () => history.push(props.backLink)} color="inherit" aria-label="back"><ArrowBackIcon /></IconButton> : <div />;

  return (
    <div>
    <AppBar position="fixed">
      <Toolbar>
        { backButton }
        <IconButton edge="start" className={classes.menuButton} onClick={ () => {(user !== null) ? history.push('/trailList') : history.push('/')}} color="inherit" aria-label="logo">
          <img alt="The Mantrailing App Logo" src={logo} width="29px" />
        </IconButton>
        <Typography variant="h6" className={classes.title}>
          { title }
        </Typography>
        { accountButton }
      </Toolbar>
    </AppBar>
    {renderMenu}
    <Toolbar />
    </div>
  );
};

export default withFirebase(NavBar);
