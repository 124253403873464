import React from "react";
import  { withFirebase } from '../utils/firebase';
import { CircularProgress } from '@material-ui/core';
import Map from '../components/Map';
import { useList } from 'react-firebase-hooks/database';
import { sortPoints } from '../utils/DataTransformer';

const MapWrapper = ( { firebase, userId, runnerId, dogId, state } ) => {

  const [points, pointsLoading, pointsError] = useList(firebase.db.ref('users/' + userId + "/dogTrack/" + dogId + "/points"));
  const [runnerPoints, runnerLoading, runnerError] = useList(firebase.db.ref('users/' + userId + "/runnerTrack/" + runnerId + "/points"));

  if (pointsLoading || runnerLoading) {
    return (
      <CircularProgress />
    );
  }

  var sortedDogPoints = [];
  if (pointsError == null) {
    sortedDogPoints = sortPoints(points.map((v) => ({
      time: v.val().time,
      lat: v.val().lat,
      lon: v.val().lng,
      key: parseInt(v.key.replace(dogId + "_", ""))
    })));
  }

  var sortedRunnerPoints = [];
  if (runnerError == null) {
    sortedRunnerPoints = sortPoints(runnerPoints.map((v) => ({
      time: v.val().time,
      lat: v.val().lat,
      lon: v.val().lng,
      key: parseInt(v.key.replace(runnerId + "_", ""))
    })));
  }

  return (
    <Map dogPoints={sortedDogPoints} runnerPoints={sortedRunnerPoints} state={state} />
  );
};

export default withFirebase(MapWrapper);
