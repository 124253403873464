import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import history from "../utils/history";
import  { withFirebase } from '../utils/firebase';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useList } from 'react-firebase-hooks/database';
import { List, ListItem, ListItemAvatar, Avatar, ListItemText } from '@material-ui/core';
import { getWeatherUrl, formatDate, getTime, getDistance } from '../utils/DataTransformer';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  listRoot: {
    width: '100%',
    maxWidth: 400,
    marginLeft: 'auto',
    marginRight: 'auto',
    backgroundColor: theme.palette.background.paper,
  },
  secondItemText: {
    float: 'right',
    textAlign: 'end'
  }
}));

const TrailList = ( {firebase, user} ) => {

  const { t, i18n } = useTranslation();

  const trailRef = "users/" + user.uid + "/dogTrack";
  const [snapshots, loading, error] = useList(firebase.db.ref(trailRef));
  const classes = useStyles();

  if (loading) {
    return (
      <CircularProgress />
    );
  }

  // Page should be protected using PrivateRoute
  return(
    <div>
      <List className={classes.listRoot}>
      {snapshots.map((v) => (
        <ListItem key={v.key} onClick={ () => history.push('/trail/' + user.uid + "/" + v.key)} button>
          <ListItemAvatar>
            <Avatar src={getWeatherUrl(v.val().info.weather)}/>
          </ListItemAvatar>
          <ListItemText primary={v.val().info.name || t('unnamed')} secondary={getTime(v.val().info.start_time, v.val().info.end_time) + ", " + getDistance(v.val().info.length)} />
          <ListItemText className={classes.secondItemText} secondary={formatDate(v.val().info.start_time)} />
        </ListItem>
      ))}
      </List>
    </div>
  );
};

export default withFirebase(TrailList);
