import React from "react";
import  { withFirebase } from '../utils/firebase';
import { CircularProgress, Grid, ListItem, ListItemText, List, ListSubheader } from '@material-ui/core';
import NavBar from '../components/NavBar';
import NoAccess404 from '../components/NoAccess404';
import MapWrapper from '../components/MapWrapper';
//import AdProvider from '../components/AdProvider';
import { useObject } from 'react-firebase-hooks/database';
import { getDeviation, getAge, getArea, formatDateTime, getTime, getBlind, getFocusAccom, getWeather, getTemperature, getAirhumidity, getAirpressure, getWindspeed } from '../utils/DataTransformer';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    [theme.breakpoints.down('xs')]: {
      height: '100vh',
    },
  },
  map: {
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      height: '46%',
    },
    [theme.breakpoints.up('sm')]: {
      height: '100%',
    },
  },
  list: {
    width: '100%',
    maxWidth: '100%',
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    overflow: 'auto',
    [theme.breakpoints.down('xs')]: {
      height: '45%',
    },
    [theme.breakpoints.up('sm')]: {
      height: '100%',
    },
    maxHeight: '90vh',
  },
  listSection: {
    backgroundColor: 'inherit',
  },
  ul: {
    backgroundColor: 'inherit',
    padding: 0,
  },
  listHeader: {
    color: theme.palette.primary.main
  },
}));

const DogTrailPage = ( { firebase } ) => {

  const { t, i18n } = useTranslation();

  const classes = useStyles();

  const isPhone = useMediaQuery('(max-width:599px)');
  let pathComp = window.location.pathname.split('/');
  const [value, loading, error] = useObject(firebase.db.ref('users/' + pathComp[2] + "/dogTrack/" + pathComp[3] + "/info"));
  const runnerId = loading || error ? "" : value.val().runner_guid;

  if (pathComp.length !== 4 || error || (!loading && value.val() === null)) {
    return (
      <div>
        <NavBar title={t('404')} backLink="/trailList" />
        <NoAccess404 />
      </div>
    );
  }

  if (loading) {
    return (
        <div>
          <NavBar title="Loading..." backLink="/trailList" />
          <CircularProgress />
        </div>
    );
  }

  // Load data for display
  const title = value.val().name + " - " + formatDateTime(value.val().start_time) + (value.val().address !== "" ? " / " + value.val().address : "");
  const listHeader = [t('trail_details'), t('train_details'), t('persons'), t('scent_art'), t('weather'), t('rating')];
  const listContent = [];

  // Adding trail details
  const trail_det = [];
  trail_det.push([getTime(value.val().start_time, value.val().end_time), t('duration')]);
  trail_det.push([getAge(value.val().ausleg_time, value.val().start_time, t), t('age')]);
  trail_det.push([getDeviation(value.val().deviation, t), t('av_deviation')]);
  trail_det.push([getArea(value.val().area, t), t('area')]);
  trail_det.push([value.val().start_point, t('start_point')]);
  trail_det.push([value.val().ground, t('ground')]);
  listContent.push(trail_det);

  // Adding trainings details
  const train_det = [];
  train_det.push([value.val().trainings_focus, t('focus')]);
  train_det.push([getBlind(value.val().blind, t), t('blind')]);
  listContent.push(train_det);

  // Adding persons
  const persons = [];
  persons.push([value.val().layer, t('layer')]);
  persons.push([value.val().backup, t('backup')]);
  persons.push([value.val().runner, t('runner')]);
  persons.push([value.val().runner_det, t('runner_det')]);
  listContent.push(persons);

  // Adding trainings details
  const scent = [];
  scent.push([value.val().scent, t('scent_art')]);
  scent.push([value.val().scent_det, t('details')]);
  listContent.push(scent);

  // Adding rating
  const weather = [];
  weather.push([getWeather(value.val().weather, t), t('weather_con')]);
  weather.push([getTemperature(value.val().temp), t('temperature')]);
  weather.push([getWindspeed(value.val().wind_speed), t('wind_speed')]);
  weather.push([getAirpressure(value.val().air_pressure), t('air_press')]);
  weather.push([getAirhumidity(value.val().air_humidity), t('air_hum')]);
  listContent.push(weather);

  // Adding rating
  const rating = [];
  rating.push([getFocusAccom(value.val().focus_accom, t), t('focus_accom')]);
  rating.push([value.val().moti_dog + t('stars'), t('dog_mot')]);
  rating.push([value.val().team_work + t('stars'), t('teamwork')]);
  rating.push([value.val().comment, t('comment')]);
  listContent.push(rating);

  const map = (<div className={classes.map} >
                <MapWrapper userId={pathComp[2]} runnerId={runnerId} dogId={pathComp[3]} state="dogWithFlag" />
              </div>);
  const list = (<List className={classes.list} subheader={<li />}>
                {/*<ListItem key={"werb"}>
                  <AdProvider />
                </ListItem>*/}
                {[0,1,2,3,4,5].map((index) => (
                  <li key={listHeader[index]} className={classes.listSection}>
                    <ul className={classes.ul}>
                      <ListSubheader className={classes.listHeader}><b>{listHeader[index]}</b></ListSubheader>
                      {listContent[index].map((item) => (
                        <ListItem key={item[1]}>
                          <ListItemText primary={item[0]} secondary={item[1]} />
                        </ListItem>
                      ))}
                    </ul>
                  </li>
                ))}
                </List>);


  // Page should be protected using PrivateRoute
  // Check for mobile or desktop layout
  if (isPhone) {
    return (
      <div className={classes.root}>
        <NavBar title={title} backLink="/trailList" />
          { map }
          { list }
      </div>
    );
  } else {
    return (
      <div className={classes.root}>
        <NavBar title={title} backLink="/trailList" />
        <Grid container spacing={3}>
          <Grid item xs={8}>
            { map }
          </Grid>
          <Grid item xs={4}>
            { list }
          </Grid>
        </Grid>
      </div>
    );
  }
};

export default withFirebase(DogTrailPage);
