import React from "react";
import  { withFirebase } from '../utils/firebase';
import NoAccess404 from '../components/NoAccess404';
import NavBar from '../components/NavBar';
import { useTranslation } from 'react-i18next';
import DogSearch from '../lottie/DogSearch';

const NotFoundPage = ( props ) => {

  const { t, i18n } = useTranslation();

  return (
    <div>
      <NavBar title={t('404')} backLink="/trailList" />
      <DogSearch />
      <NoAccess404 />
    </div>
  );
};

export default withFirebase(NotFoundPage);
