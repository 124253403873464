import React from "react";
import { useTranslation } from 'react-i18next';

const NoAccess404 = () => {

  const { t, i18n } = useTranslation();

  return (
    <div style={{marginTop: "2vw"}}>{t('4041')}<br/>{t('4042')}</div>
  );
};

export default NoAccess404;
