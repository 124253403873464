import React from "react";
import ReactMapboxGl, { Layer, Feature, GeoJSONLayer, ScaleControl, Marker } from 'react-mapbox-gl';
import mapboxgl from 'mapbox-gl';
import flagImg from'../assets/flag.svg';
import runnerImg from'../assets/runner.svg';
import dogImg from'../assets/dog.svg';

const token = 'pk.eyJ1IjoibW9qbzE5MTciLCJhIjoiY2tiMG9qMno1MDc0YTJ1bG81aWRuMzdzMyJ9.pSMwZjXIWUF_wDtH7t4Z3Q';

const Mapbox = ReactMapboxGl({
  minZoom: 11,
  maxZoom: 22,
  accessToken: token,
});

const getGeoJsonLine = (coords) => {
  return {type: 'Feature', properties: {}, geometry: { type: 'LineString', coordinates: coords }}
};
const getGeoJsonIcon = (coords) => {
  return {type: 'Feature', properties: {}, geometry: { type: 'Point', coordinates: coords }}
};

class Map extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      map: null,
      props: props,
    };

  }

  componentDidUpdate(nextProps, nextState) {

    this.updateMapWithProps(nextProps, nextState);

  }

  // Store map for later updates of data
  onStyleLoad = (map, e) => {
    this.setState({map: map})

    // Update map on create with initial props
    this.updateMapWithProps(this.state.props);
  }

  updateMapWithProps = (props, nextState = this.state) => {
    const dogPoints = props.dogPoints.map((point) => [point.lon, point.lat]);
    const runnerPoints = props.runnerPoints.map((point) => [point.lon, point.lat]);

    var coordinates = dogPoints.concat(runnerPoints);
    const bounds = coordinates.reduce(function(bounds, coord) {
      return bounds.extend(coord);
    }, new mapboxgl.LngLatBounds(coordinates[0], coordinates[0]));

    const { map } = nextState;
    if (map) {
      map.setCenter(bounds.getCenter());
      map.fitBounds(bounds, {padding: 40});
      map.getSource('dog_line').setData(getGeoJsonLine(dogPoints));
      map.getSource('runner_line').setData(getGeoJsonLine(runnerPoints));

      if (nextState.props.state === 'dogWithFlag')
        map.getSource("flag").setData(getGeoJsonIcon(runnerPoints.length === 0 ? dogPoints[dogPoints.length - 1] : runnerPoints[runnerPoints.length - 1]));

      if (nextState.props.state === 'live' && dogPoints.length > 0)
        map.getSource("dog").setData(getGeoJsonIcon(dogPoints[dogPoints.length - 1]));

      if (nextState.props.state === 'live' && runnerPoints.length > 0)
        map.getSource("runner").setData(getGeoJsonIcon(runnerPoints[runnerPoints.length - 1]));
    }
  }

  componentDidMount() {

  }

  componentWillUnmount() {

  }

  render() {

    const flag = new Image(74, 63);
    flag.src = flagImg;
    const runner = new Image(40, 40);
    runner.src = runnerImg;
    const dog = new Image(30, 30);
    dog.src = dogImg;
    const images = [['flag', flag], ['runner', runner], ['dog', dog]];

    return (
      <Mapbox
        style={"mapbox://styles/mojo1917/cjkwqbfqh02sb2rn6rqdxs71b"}
        containerStyle={{height: "100%", width: "100%"}}
        center={this.state.props.dogPoints[0]}
        interactive={false}
        onStyleLoad={this.onStyleLoad} >
      <ScaleControl position="top-right" />
      <GeoJSONLayer
        id="runner_line"
        data={getGeoJsonLine([])}
        linePaint={{'line-color': '#1776d0', 'line-width': 3}} />
      <GeoJSONLayer
        id="dog_line"
        data={getGeoJsonLine([])}
        linePaint={{'line-color': '#ff0000', 'line-width': 3}} />
      <GeoJSONLayer
        id="flag"
        data={getGeoJsonIcon([])} />
      <Layer
        type="symbol"
        sourceId="flag"
        layout={{ "icon-image": 'flag' }}
        images={images} />
      <GeoJSONLayer
        id="runner"
        data={getGeoJsonIcon([])} />
      <Layer
        type="symbol"
        sourceId="runner"
        layout={{ "icon-image": 'runner' }}
        images={images} />
      <GeoJSONLayer
        id="dog"
        data={getGeoJsonIcon([])} />
      <Layer
        type="symbol"
        sourceId="dog"
        layout={{ "icon-image": 'dog' }}
        images={images} />
    </Mapbox>
    );
  }
}

export default Map;
