import React from 'react';
import './App.css';
import { Router, Route, Switch } from "react-router-dom";
import history from "./utils/history";
import AuthorizedRoute from "./utils/AuthorizedRoute";
import LandingPage from "./pages/LandingPage";
import Footer from "./components/Footer";
import TrailListPage from "./pages/TrailListPage";
import DogTrailPage from "./pages/DogTrailPage";
import NotFoundPage from "./pages/NotFoundPage";
import LivePage from "./pages/LivePage";
import ThirdPartyPage from "./pages/ThirdPartyPage";
import AccountSettingsPage from "./pages/AccountSettingsPage";
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import CookieConsent from "react-cookie-consent";
import { useTranslation } from 'react-i18next';

function App() {

  const { t, i18n } = useTranslation();

  const theme = createMuiTheme({
    palette: {
      primary: {
        main: '#1776d0',
      },
      secondary: {
        main: '#f3b518',
      },
    }
  });

  const footer = window.location.pathname === "/" ? <div /> : <Footer />;
  return (
    <div className="App">
      <Router history={history}>
        <ThemeProvider theme={theme}>
          <CookieConsent
            location="bottom"
            buttonText="Ok"
            declineButtonText="More Info"
            cookieName="cookieAccept"
            style={{ background: "#2B373B" }}
            buttonStyle={{ background: '#1776d0', color: "#fff", fontSize: "13px" }}
            declineButtonStyle={{ background: '#1776d0', color: "#fff", fontSize: "13px" }}
            enableDeclineButton={true}
            expires={150}
            setDeclineCookie={false}
            onDecline={() => window.open("https://the-mantrailing-app.com/en/privacy-policy/")}
            >
            {t('cookie_note')}
            </CookieConsent>
          <Switch>
            <Route path="/" exact component={LandingPage}/>
            <AuthorizedRoute path="/trailList" exact component={TrailListPage}/>
            <Route path="/trail" component={DogTrailPage}/>
            <Route path="/live" component={LivePage}/>
            <Route path="/openSourceCopyright" component={ThirdPartyPage}/>
            <AuthorizedRoute path="/account-settings" exact component={AccountSettingsPage}/>
            <Route component={NotFoundPage} />
          </Switch>
          { footer }
        </ThemeProvider>
      </Router>
    </div>
  );
}
export default App;
