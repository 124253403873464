import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/analytics';

const config = {
    apiKey: "AIzaSyD9v1fbeNBnTsalln0WW5iBOhxPv2L5IZ8",
    authDomain: "sharp-gecko-90111.firebaseapp.com",
    databaseURL: "https://sharp-gecko-90111.firebaseio.com",
    projectId: "sharp-gecko-90111",
    storageBucket: "sharp-gecko-90111.appspot.com",
    messagingSenderId: "430156230386",
    appId: "1:430156230386:web:c11ce14c69da8d9b952a6b",
    measurementId: "G-RD8GJED74D"
};

class Firebase {
  constructor() {

    app.initializeApp(config);

    this.auth = app.auth();
    this.db = app.database();
    this.analytics = null;

    // Only start analytics in production
    const keepThis = this;
    this.auth.onAuthStateChanged(function(user) {
      if(process.env.NODE_ENV !== "development") {
        keepThis.analytics = app.analytics();
      }
    });

  }

}

export default Firebase;
