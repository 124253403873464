import React from "react";
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import history from "../utils/history";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#1776d0',
    padding: '2.5vw',
    marginTop: '2vw',
    color: 'white',
    fontSize: 12,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  below: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'right',
  },
  link: {
    color: 'white',
    textDecoration: 'none',
    cursor: 'pointer'
  }
}));

const Footer = (props) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  const currentYear = 2020 < new Date().getFullYear() ? new Date().getFullYear() : 2020;
  return (
    <div className={classes.root} >
      <div >
        Copyright © Niklas Kuse 2015-{ currentYear }. All rights reserved.
      </div>
      <div className={classes.below} >
        <a className={classes.link} href="https://the-mantrailing-app.com/en/general-terms-and-conditions/">General Terms and Conditions</a>
        <a className={classes.link} href="https://the-mantrailing-app.com/en/privacy-policy/">Privacy Policy</a>
        <a className={classes.link} onClick={() => {history.push('/openSourceCopyright')}} >{t("thrid_party")}</a>
        <a className={classes.link} href="https://the-mantrailing-app.com/en/imprint/">Imprint</a>
      </div>
    </div>
  );
};

export default Footer;
