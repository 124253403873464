import React from "react";
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import  { withFirebase } from '../utils/firebase';
import firebase from 'firebase/app';
import { useAuthState } from 'react-firebase-hooks/auth';
import CircularProgress from '@material-ui/core/CircularProgress';
import { MainText } from '../styles/styles'
import history from "../utils/history";
import { useTranslation } from 'react-i18next';

var firebaseui = require('firebaseui');

const Signup = (props) => {

  const { t, i18n } = useTranslation();

  const auth = props.firebase.auth;
  const redirectOnLogin = props.redirect || '/trailList';

  const [user, loading, error] = useAuthState(auth);

  if (loading) {
    return (
      <CircularProgress />
    );
  }

  if (user !== null) {
    history.push(redirectOnLogin);
    return(<div />);
  }

  // Configure FirebaseUI.
  const uiConfig = {
    // Popup signin flow rather than redirect flow.
    signInFlow: 'popup',
    // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
    signInSuccessUrl: redirectOnLogin,
    // We will display Google and Facebook as auth providers.
    signInOptions: [
      firebase.auth.EmailAuthProvider.PROVIDER_ID,
      firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      firebase.auth.FacebookAuthProvider.PROVIDER_ID,
      'apple.com'
    ],
    tosUrl: 'https://the-mantrailing-app.com/en/general-terms-and-conditions/',
    privacyPolicyUrl: 'https://the-mantrailing-app.com/en/privacy-policy/',
    credentialHelper: firebaseui.auth.CredentialHelper.NONE
  };

  return (
    <div>
      <MainText>{t('sign_up_text')}</MainText>
      <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={auth}/>
    </div>
  );
};

export default withFirebase(Signup);
