import i18n from 'i18next'
import LanguageDetector from "i18next-browser-languagedetector"
import {initReactI18next} from 'react-i18next'
import HttpApi from 'i18next-http-backend';
import languageDE from '../locale/de.json'
import languageEN from '../locale/en.json'

const detector = {
  name: 'customDetector',

  lookup(options) {
    // options -> are passed in options
    const browserLang = navigator.language || navigator.userLanguage;
    if (browserLang.startsWith("de")) {
        return 'de';
    }
    return 'en';
  },

  cacheUserLanguage(lng, options) { },
};

const languageDetector = new LanguageDetector();
languageDetector.addDetector(detector);

i18n
.use(HttpApi)
.use(languageDetector)
.use(initReactI18next)
.init({
    resources: {
        en: languageEN,
        de: languageDE
    },
    /* default language when load the website in browser */
    /*lng: "en",*/
    /* When react i18next not finding any language to as default in borwser */
    fallbackLng: "en",
    /* debugger For Development environment */
    debug: false,
    ns: ["translations"],
    defaultNS: "translations",
    keySeparator: ".",
    interpolation: {
        escapeValue: false,
        formatSeparator: ","
    },
    react: {
        wait: false,
        bindI18n: 'languageChanged loaded',
        bindStore: 'added removed',
        nsMode: 'default',
        useSuspense: true,
    },
    detection: {
      order: ['customDetector']
    }
})

export default i18n;
