import styled, { css } from "styled-components";

export const FullPageWrapper = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
export const FullPageWrapperBgImage = styled(FullPageWrapper)`
  background-image: url("${props => props.bgImage}");
  background-size: cover;
`;
export const FullPageWrapperGradient = styled(FullPageWrapper)`
  background: linear-gradient(270deg, #5dd09f, #8ec82c, #f3b518);
  background-size: 600% 600%;

  -webkit-animation: AnimationName 30s ease infinite;
  -moz-animation: AnimationName 30s ease infinite;
  -o-animation: AnimationName 30s ease infinite;
  animation: AnimationName 30s ease infinite;
`;
export const FullPageWrapperBlur = styled(FullPageWrapper)`
  filter: blur(2px);
`;

export const AlertWrapper = styled.div`
  padding: 2vw;
  padding-bottom: 0vw;
`;

export const DivFlexHorizontal = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  ${props => props.backblured && css`
    backdrop-filter: blur(10px);
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 5px;
    padding: 2vw;
    padding-left: 5vw;
    padding-right: 5vw;
  `}
`;

export const DivFlexVertical = styled(DivFlexHorizontal)`
  flex-direction: column;
`;

/*export const Button = styled(ButtonMat)`
  background-color: red;
`;*/

/*export const Button = styled(({ color, ...other }) => <ButtonMat {...other} />)({
  background: (props) =>
    props.color === 'red'
      ? 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)'
      : 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)',
  border: 0,
  borderRadius: 3,
  boxShadow: (props) =>
    props.color === 'red'
      ? '0 3px 5px 2px rgba(255, 105, 135, .3)'
      : '0 3px 5px 2px rgba(33, 203, 243, .3)',
  color: 'white',
  height: 48,
  padding: '0 30px',
  margin: 8,
});*/

export const Button = styled.button`
  background: white;
  border-radius: 3px;
  margin: 0.5em 1em;
  padding: 0.5em 1em;
  border: 2px solid transparent;
  font-weight: 600;
  font-size: 1.2vw;

  &:hover {
    background: #dbdbdb;
  }

  ${props => props.lightGreen && css`
    color: #7cb342;
    ${props => props.primary && css`
      background: #7cb342;
      &:hover {
        background: #7cb342;
      }
    `}
    border-color: #7cb342;
  `}
  ${props => props.green && css`
    color: #43a047;
    ${props => props.primary && css`
      background: #43a047;
      &:hover {
        background: #43a047;
      }
    `}
    border-color: #43a047;
  `}
  ${props => props.lightRed && css`
    color: #ff8a65;
    ${props => props.primary && css`
      background: #ff8a65;
      &:hover {
        background: #ff8a65;
      }
    `}
    border-color: #ff8a65;
  `}
  ${props => props.red && css`
    color: #e53935;
    ${props => props.primary && css`
      background: #e53935;
      &:hover {
        background: #e53935;
      }
    `}
    border-color: #e53935;
  `}

  ${props => props.primary && css`
    color: white;
  `}

  ${props => props.disabled && css`
    color: gray;
    border-color: gray;
    background: white;
  `}

  ${props => {
    let a = props.styles || '';
    return a;
  }};
`;

export const AccountControllButton = styled.button`
  backdrop-filter: blur(10px);
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  padding: 1vw 2vw;
  position: absolute;
  font-weight: 600;
  right: 1vw;
  top: 1vw;
`;

export const PosterImg = styled.img`
  max-height: 35vw;
  width: auto;
`;

export const Title = styled.span`
  font-family: 'Roboto', sans-serif;
  color: black;
  margin-bottom: 3vw;
  margin-top: 3vw;
  font-size: 3vw;
`;
export const MainText = styled.div`
  font-family: 'Roboto', sans-serif;
  color: black;
  margin-bottom: 2vw;
`;

export const ColoredTextBox = styled.span`
  color: white;
  border-radius: 3px;
  background-color: #f44336;
  padding-left: 1vw;
  padding-right: 1.5vw;
`;

export const PersonContainer = styled.div`
  border-radius: 3px;
  margin: 0.5vw;
  height: 180px;
  width: 120px;
  background-size: cover;
  display: flex;
  align-items: flex-end;
  background-color: black;

  ${props => props.image && css`
    background-image: url(${props.image});
  `}
`;

export const PosterGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: auto;
  grid-row-gap: 1.5vw;
  grid-column-gap: auto;
  justify-items: center;
  align-items: center;
  padding: 2vw;
`;

export const PosterContainer = styled.div`
  border-radius: 3px;
  margin: 0.5vw;
  height: 25.5vw;
  width: 17vw;
  background-size: cover;
  display: flex;
  background-color: black;
  overflow: hidden;

  ${props => props.image && css`
    background-image: url(${props.image});
  `}
`;

export const RatingControllVertWrapper = styled(DivFlexVertical)`
  margin: 0;
  align-items: stretch;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  justify-content: center;
  width: 100%;
  padding: 0.5vw;

  &:hover {
    opacity: 1;
  }
`;

export const WhiteOnBlackTextContainer = styled.div`
  padding: 0.5vw 1vw;
  color: white;
  width: 100%;
  font-weight: 600;
  bottom: 0px;
  background-color: rgba(0, 0, 0, 0.5);
`;
