import React from "react";
import  { withFirebase } from '../utils/firebase';
import NavBar from '../components/NavBar';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { useObject } from 'react-firebase-hooks/database';
import MapWrapper from '../components/MapWrapper';
import DogSearch from '../lottie/DogSearch';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    [theme.breakpoints.down('xs')]: {
      height: '90vh',
    },
    [theme.breakpoints.up('sm')]: {
      height: '81vh',
    },
  },
  map: {
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      height: '91%',
    },
    [theme.breakpoints.up('sm')]: {
      height: '100%',
    },
  },
}));

const LivePage = ( { firebase } ) => {

  const { t, i18n } = useTranslation();
  const classes = useStyles();

  let pathComp = window.location.pathname.split('/');
  const trackType = pathComp[2] == 'runner' ? 0 : 1;
  const userId = pathComp[3];
  const trackId = pathComp[4];

  const dataPath = 'users/' + userId + (trackType == 0 ? '/runnerTrack/' : '/dogTrack/') + trackId + '/info'
  const [value, loading, error] = useObject(firebase.db.ref(dataPath));

  const runnerId = loading || error ? "" : trackType == 0 ? trackId : value.val().runner_guid;

  const dogId = trackType == 1 ? trackId : ""

  // Check for 404
  if (pathComp.length !== 5 || error || (!loading && value.val() === null)) {
    return (
      <div>
        <NavBar title={t('live_tracking')}/>
        <DogSearch />
        <div style={{marginTop: "2vw"}}>{t('live_404')}<br/><br/>{t('sign_up_logo')}</div>
      </div>
    );
  }

  const map = (<div className={classes.map} >
                <MapWrapper userId={userId} runnerId={runnerId} dogId={dogId} state="live"  />
              </div>);

  return (
    <div className={classes.root}>
      <NavBar title={t('live_tracking')}/>
        { map }
    </div>
  );
};

export default withFirebase(LivePage);
