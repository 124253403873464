import React from "react";
import  { withFirebase } from '../utils/firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import CircularProgress from '@material-ui/core/CircularProgress';
import NavBar from '../components/NavBar';
import TrailList from '../components/TrailList';

const TrailListPage = ( {firebase} ) => {

  const [user, loading, error] = useAuthState(firebase.auth);

  if (loading || user === null) {
    return (
      <div>
        <NavBar title="Trails" />
        <CircularProgress />
      </div>
    );
  }

  // Page should be protected using PrivateRoute
  return (
      <div>
        <NavBar title="Trails" />
        <TrailList user={user} />
      </div>
  );
};

export default withFirebase(TrailListPage);
