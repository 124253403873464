import React from "react";
import  { withFirebase } from '../utils/firebase';
import LandingPage from "../pages/LandingPage";
import { Route } from "react-router-dom";
import { useAuthState } from 'react-firebase-hooks/auth';

const AuthorizedRoute = ({ component: Component, path, firebase, ...rest }) => {

  const [user, loading, error] = useAuthState(firebase.auth);

  if (loading) {
    return <div />;
  }

  const render = props => user != null ? <Component {...props} /> : <LandingPage redirect={window.location.pathname} />;

  return <Route path={path} render={render} {...rest} />;
};

export default withFirebase(AuthorizedRoute);
