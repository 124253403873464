import React from 'react';
import { Lottie } from '@crello/react-lottie'
import animationData from '../assets/dog-search.json'

const DogSearch = ( props ) => {
  return(
    <Lottie config={{animationData: animationData, loop: true, autoplay: true, renderer: 'canvas'}} style={{height: "300px", marginLeft: "-75px"}} />
  );
}

export default DogSearch;
